body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  margin: auto;
  right: 0px;
}

.vadapaav-thumb {
  background: #100d0a;
  position: absolute;
  left: 0px;
  bottom: 50px;
  margin: auto;
  right: 0px;
}

.background {
  background-color: rgb(16, 13, 10);
  left: 0px;
  right: 0px;
  position: absolute;
  top: 0px;
  bottom: 0px;
}


.loader-holder {
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  margin: auto;
  right: 0px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 25px;
  right: 25px;
  border-radius: 60px;
  text-align: center;
  border-color: transparent;
  background-size: contain;
  box-shadow: 0px 0 5px 4px #5d5d5d;
  background-color: rgb(0, 0, 0);
  background-repeat: no-repeat;
}

.my-float {
  margin-top: 22px;
}

.icon {
  width: 100%;
  aspect-ratio: 1;
}